import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { useUtilityContext } from '../../../utility-provider';
import SelectPlan from '../SelectPlan';

import {
    Button,
    Box,
} from "@mui/material";
import { Link } from "react-router-dom";


export interface photoIndexProps {
    props:{
    }
}


const PhotoIndex:React.FC<photoIndexProps> = ({props}) => {
    const receptionBook = useAppSelector((state) => state.receptionBook.select);
    const [disabled, setDisabled] = React.useState<boolean>(false)
    const backRef = React.useRef<HTMLDivElement>(null);

    
    React.useEffect(() => {
        setDisabled(true)
        if (receptionBook.plan_area_id !== "") {
            setDisabled(false)
        }
    }, [receptionBook])


    const clickHandle = () => {
        if (!disabled) {
          const link = document.getElementById("photo-upload");
          if (link) {
              link.click()
          }  
        }
    }

    const clickBack = () => {
        if (backRef) {
            backRef.current?.click();
        }
    }
    
    return (
        <Box style={{ width: "100%", margin: 0, padding: 0, position: 'relative' }}>
            <Box sx={{margin: '0 10px'}}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{margin: '4px', minWidth: '30px', padding: '10px 0',
                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                     }}
                    onClick={clickBack}
                >≪</Button>
                <Link to="/"><div ref={backRef}></div></Link>
            </Box>
            <Box sx={{margin: '10px'}}>
                <SelectPlan props={{}} />
            </Box>
            <Box sx={{margin: '20px 10px'}}>
                <Link id="photo-upload" to={"/Photo/upload?planAreaId="+receptionBook.plan_area_id+"&referrer=" }>
                </Link>
                <Button
                    variant="contained" 
                    color="success"
                    disabled={disabled}
                    onClick={clickHandle}
                >アップロード</Button>
            </Box>
        </Box>
    )
}

export default PhotoIndex;