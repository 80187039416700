import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { useUtilityContext } from '../../../utility-provider';
import { tokenLogin } from '../../../utility/firebase';
import { getMultipleData } from "../../../api/DataManage"
import { setStoreCode, setLocationLinkObj, setExistingSejStoreObj } from '../../../features/rviewStoreSlice';

import {
    Box,
    Button,
    Select,
    MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useUserContext } from '../../../user-provider';
import Computer from '@mui/icons-material/Computer';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import ResearchBranchModal from '../ResearchBranchModal';

export interface resultProps {
    props: {
    }
}

const StoreMenu: React.FC<resultProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();
    const [userData, setUserData] = React.useState<any>({});
    const dispatch = useAppDispatch();

    //URLパラメータ
    const [searchParams] = useSearchParams();
    const storecode = searchParams.get("storecode");
    const [planAreaId, setPlanAreaId] = React.useState("")
    // 受付簿
    const objLocationLink = useAppSelector((state) => state.rviewStoreData.locationLink);
    const objExistingSejStore = useAppSelector((state) => state.rviewStoreData.existingSejStore);
    //モーダル開閉フラグ(調査書枝選択画面)
    const [researchBranchOpen, setResearchBranchOpen] = React.useState(false);
    const [value, setValue] = React.useState<string>("")

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    React.useEffect(() => {
        if (storecode && userData) {
            selectLocationLink()
            dispatch(setStoreCode(storecode))
        }
    }, [storecode, userData])

    const selectLocationLink = () => {
        if (Object.keys(userData).length > 0) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner()
            }
            // 候補地呼び出し
            callApi('locationLink')
                .then((res: any) => {
                    dispatch(setLocationLinkObj(res.getLocationLink))
                    // 既存店
                    dispatch(setExistingSejStoreObj(res.getExistingSejStore[0]))
                }).catch((e) => {
                    console.log(e)
                }).finally(() => {
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                })
        }
    }

    const callApi = async (target: string) => {
        const param: any = []
        if (target === 'locationLink') {
            param.push({
                mode: 'getLocationLink',
                storeCode: storecode,
            })
            param.push({
                mode: 'getExistingSejStore',
                storeCode: storecode,
            })
            return await getMultipleData(param)
        }
    }

    //モーダルオープン(調査書枝選択画面)
    const handleResearchBranchOpen = () => {
        setResearchBranchOpen(true);
    };


    const selectHandler = (event: any) => {
        setValue(event.target.value)
        objLocationLink.map((row: any) => {
            if (row.link_plan_area_id === event.target.value) {
                setPlanAreaId(row.use_plan_area_id)
            }
        })
    }

    const hasDisabled = () => {
        return planAreaId != "" ? false : true
    }

    return (
        <>
            <Box sx={{ margin: '0 auto', padding: '10px' }}>
                <Box sx={{ margin: '10px 0', textAlign: 'center' }}>立地調査</Box>
                <Box sx={{ margin: '10px 0', display: 'flex', justifyContent: 'center' }}>{objExistingSejStore.store_code}：{objExistingSejStore.tenpo_name}</Box>
                <Box sx={{ margin: '20px 0', textAlign: 'center' }}>
                    <Select
                        sx={{ margin: '10px 0', minWidth: '260px', width: '260px' }}
                        value={value}
                        onChange={(event) => selectHandler(event)}
                    >
                        {objLocationLink?.map((row: any) => (
                            <MenuItem
                                key={row.link_plan_area_id}
                                value={row.link_plan_area_id}>
                                {'候補地No：' + row.link_plan_area_id}</MenuItem>
                        ))}
                    </Select>
                    <Box>
                        <Button onClick={handleResearchBranchOpen} sx={{ margin: '10px 0', minWidth: '200px', width: '200px' }} variant="contained" color="success" disabled={hasDisabled()}>
                            <Box sx={{ position: 'absolute', left: '0', margin: '6px 0 0 10px' }}><Computer /></Box>調査書</Button>
                    </Box>
                    <Box>
                        <Link to={"/Photo/upload?planAreaId=" + planAreaId + "&referrer=rview-store"}>
                            <Button sx={{ margin: '10px 0', minWidth: '200px', width: '200px' }} variant="contained" color="success" disabled={hasDisabled()}>
                                <Box sx={{ position: 'absolute', left: '0', margin: '6px 0 0 10px' }}><PhoneIphone /></Box>写真登録</Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to={"/TrafficVolume/select?planAreaId=" + planAreaId + "&referrer=rview-store"}>
                            <Button sx={{ margin: '10px 0', minWidth: '200px', width: '200px' }} variant="contained" color="success" disabled={hasDisabled()}>
                                <Box sx={{ position: 'absolute', left: '0', margin: '6px 0 0 10px' }}><PhoneIphone /></Box>通行量調査</Button>
                        </Link>
                    </Box>
                </Box>
                <ResearchBranchModal props={{ modalOpen: researchBranchOpen, setModalOpen: setResearchBranchOpen, planAreaId: planAreaId }} />
            </Box>
        </>
    )
}

export default StoreMenu;
