import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { useUtilityContext } from '../../../utility-provider';
import { tokenLogin } from '../../../utility/firebase';
import { planAreaSearchById } from "../../../api/DataManage"
import { setReceptionBook } from '../../../features/receptionBookSlice';

import {
    Box,
    Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import ConfirmDialog from '../../common/ConfirmDialog';
import { useSearchParams } from "react-router-dom";
import { useUserContext } from '../../../user-provider';
import Computer from '@mui/icons-material/Computer';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import ResearchBranchModal from '../ResearchBranchModal';

export interface resultProps {
    props: {
    }
}

const Planareamenu: React.FC<resultProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();
    const [userData, setUserData] = React.useState<any>({});
    const dispatch = useAppDispatch();

    //URLパラメータ
    const [searchParams] = useSearchParams();
    const planAreaId = searchParams.get("planAreaId");
    const [planArea, setPlanArea] = React.useState<any>([]);
    // 受付簿
    const objReceptionBook = useAppSelector((state) => state.receptionBook.select);
    //モーダル開閉フラグ(調査書枝選択画面)
    const [researchBranchOpen, setResearchBranchOpen] = React.useState(false);

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    React.useEffect(() => {
        if (planAreaId && userData) {
            getPlanArea(planAreaId)
        }
    }, [planAreaId, userData])

    const getPlanArea = (planAreaId: string) => {
        if (Object.keys(userData).length > 0) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner()
            }
            // 候補地呼び出し
            callApi('planArea')
                .then((res: any) => {
                    setPlanArea(res[0])
                    dispatch(setReceptionBook({
                        ...objReceptionBook,
                        plan_area_id: res[0]["plan_area_id"],
                        plan_area_name: res[0]["plan_area_name"]
                    }))

                }).catch((e) => {
                    console.log(e)
                }).finally(() => {
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                })
        }
    }

    const callApi = async (target: string) => {
        if (target === 'planArea') {
            const jsonUserData = JSON.parse(user.userData);
            let manageCitiesFlg = true
            if (jsonUserData.administrator_flag === "1" || jsonUserData.manage_cities?.includes("ALL")) {
                manageCitiesFlg = false;
            }
            const manage_steps = ['0', '8', '99']
            let stepCnt = jsonUserData.manage_steps.filter(
                (val: any) => manage_steps.includes(val) === false
            );
            let managePlansOutsideCities = jsonUserData.manage_plans_outside_cities;
            if (!managePlansOutsideCities.includes("")) {
                managePlansOutsideCities.push("");
            }
            return await planAreaSearchById({
                mode: "planAreaSearchById",
                id: planAreaId,
                manageCitiesFlg: manageCitiesFlg,
                manageCities: jsonUserData.manage_cities.join(','),
                manageStep: stepCnt.join(','),
                managePlansOutsideCities: managePlansOutsideCities.join(','),
            })
        }
    }

    //モーダルオープン(調査書枝選択画面)
    const handleResearchBranchOpen = () => {
        setResearchBranchOpen(true);
    };

    return (
        <>
            <Box sx={{ margin: '0 auto', padding: '10px' }}>
                <Box sx={{ margin: '10px 0', textAlign: 'center' }}>立地調査</Box>
                <Box sx={{ margin: '10px 0', display: 'flex', justifyContent: 'center' }}>{planAreaId}：{planArea.plan_area_name}</Box>
                <Box sx={{ margin: '20px 0', textAlign: 'center' }}>
                    <Box>
                        <Button onClick={handleResearchBranchOpen} sx={{ margin: '10px 0', minWidth: '200px', width: '200px' }} variant="contained" color="success">
                            <Box sx={{ position: 'absolute', left: '0', margin: '6px 0 0 10px' }}><Computer /></Box>調査書</Button>
                    </Box>
                    <Box>
                        <Link to={"/Photo/upload?planAreaId=" + planAreaId + "&referrer=rview"}>
                            <Button sx={{ margin: '10px 0', minWidth: '200px', width: '200px' }} variant="contained" color="success">
                                <Box sx={{ position: 'absolute', left: '0', margin: '6px 0 0 10px' }}><PhoneIphone /></Box>写真登録</Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to={"/TrafficVolume/select?planAreaId=" + planAreaId + "&referrer=rview"}>
                            <Button sx={{ margin: '10px 0', minWidth: '200px', width: '200px' }} variant="contained" color="success">
                                <Box sx={{ position: 'absolute', left: '0', margin: '6px 0 0 10px' }}><PhoneIphone /></Box>通行量調査</Button>
                        </Link>
                    </Box>
                </Box>
                <ResearchBranchModal props={{ modalOpen: researchBranchOpen, setModalOpen: setResearchBranchOpen, planAreaId: planAreaId }} />
            </Box>
        </>
    )
}

export default Planareamenu;
